import React from 'react';
import detectBrowserLanguage from 'detect-browser-language';
const language = detectBrowserLanguage();
let locale = language.includes('en') ? 'en' : 'es';

const LinkButton = ({name, name_es, pageview, background, icon, link, iconSize}) => (
  <button
    style={{backgroundColor: background}}
    onClick={() => {
      
      window.location.href = link;
    }}
  >
    <span
      style={{fontSize: iconSize}}
      className={`icon-${icon}`}
    />
    {locale === 'es' ? (name_es ? name_es : name) : name}
  </button>
);

export default LinkButton;